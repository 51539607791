@font-face {
  font-family: 'KGRedHands';
  src: url(../public/assets/fonts/KGRedHands.ttf);
}

@font-face {
  font-family: 'Champagne';
  src: url(../public/assets/fonts/Champagne\ _\ Limousines.ttf);
}

#root {
  height: 100%;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  height: 100%;
}


body {
  margin: 0;
  padding: 0;
  /* font-family: 'Poppins', sans-serif; */
  background-color: #000;
  overflow-x: hidden;
  color: #fff;
  background-image: url(../public/assets/bg1.jpg);
  background-size: inherit;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: inherit;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
  border: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}

.bg-black{
  background: rgb(255 255 255 / 70%) !important;
  backdrop-filter: blur(10px);
}
.fs-14 {
  font-size: 14px !important;
}

/* Header  */

.navbar {
  /* height: 85px; */
  position: fixed;
  width: 100%;
  z-index: 111;
  /* background: rgba(0, 0, 0, 0.70);
  backdrop-filter: blur(10px); */
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.18px;
  color: #FFFFFF;
  margin: 0 5px;
  position: relative;
  transition: all .2s ease-in-out;
  text-align: center;
}

.navbar .nav-link:hover {
  color: #61DFFF;
  transition: all .2s ease-in-out;
}


.launch {
  font-size: 26px;
  color: #fff;
  text-align: center;
  background: #0360EE;
  border-radius: 14px;
  padding: 0px 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: 'Champagne';
  font-weight: bold;
  border: 2px solid #01A8EA;
  transition: all .5s;
  position: relative;
}

.launch:hover {
  color: #fff;
  background: transparent;
  transition: all .5s;
}

.launch span{
  position: absolute;
  bottom: -25px;
  color: #020451;
  font-size: 16px;
  white-space: nowrap;
  text-transform: capitalize;
  font-weight: 600;
}

.navbar-collapse.collapsing {
  transition: none !important;
}

/* Banner */

.banner {
  padding-top: 80px;
  padding-bottom: 0px;
  display: flex;
  align-items: center;

}

.banner h1 {
  font-weight: 400;
  font-size: 28px;
  color: #003974;
  font-family: 'KGRedHands';
  letter-spacing: 2px;

}
.banner .base-logo{
      height: 140px;
}

.social-icons{
  position: fixed;
    bottom: 0;
    left: 0;
    /* max-width: 1320px; */
    width: 100%;
    margin: 0 auto;
    right: 0;
    padding: 25px;
    display: flex;
    gap: 20px;
    z-index: 22;
    justify-content: end;
}
.social-icons img{
      height: 40px;
  transition: all .2s ease-in-out;
}

.social-icons img:hover{
  transform: translateY(-5px);
  transition: all .2s ease-in-out;
}

.primary-btn {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #000;
  background: linear-gradient(0deg, #61DFFF, #61DFFF), linear-gradient(100.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  border-radius: 50px;
  height: 54px;
  width: 180px;
  padding: 14px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
}

.primary-btn:hover {
  background: linear-gradient(100deg, #61DFFF, #61DFFF), linear-gradient(0.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  transition: all .3s ease-in-out;
  color: white;
}

.primary-btn-outline {
  background: linear-gradient(0deg, #61DFFF, #61DFFF), linear-gradient(100.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  border-radius: 50px;
  display: inline-flex;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: white;
  height: 54px;
  max-height: 64px;
  width: 180px;
  padding: 2px;
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.primary-btn-outline:hover {
  background: linear-gradient(100deg, #61DFFF, #61DFFF), linear-gradient(0.92deg, #EB02A6 39.81%, #0C4FFF 90.21%);
  color: #fff;
  transition: all .1s ease-in-out;
}

.primary-btn-outline span {
  background: #000;
  display: inline-flex !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height: 100%;
  transition: all .3s ease-in-out;

}

.primary-btn-outline:hover span {
  background: transparent !important;
  transition: all .3s ease-in-out;

}

.primary-badge {
  background: #002B56;
  border-radius: 8px;
  display: inline-flex;
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
  padding: 8px 15px;
  font-family: 'KGRedHands';
  align-items: center;
  justify-content: center;
  transition: all .1s ease-in-out;
}

.transition-all {
  transition-duration: .15s !important;
  transition-property: all !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
}

.z-1 {
  z-index: 1;
}

.sowrd{
  position: absolute;
  top: 0;
  right: 0;
  max-width: 30%;
  width: 100%;
  height: 100vh;
}
.sowrd img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.box-ic {
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 50px;
}

.ic-img {
  height: 80px;
  width: 80px;
  margin: 0 auto;
}

.ic-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.text {
  font-size: 28px;
  line-height: 36px;
  margin-top: 20px;
  font-weight: 600;
  color: #020451;
  font-family: 'Champagne';
}

footer{
  background: linear-gradient(180deg, rgba(16, 18, 75, 1) 35%, rgba(12, 53, 126, 1) 100%);

}
.footer-link{
  font-size: 24px;
  transition: all .2s ease-in-out;
}
.footer-link:hover {
  transform: translate(0px, -5px);
  transition: all .2s ease-in-out;
}
@media (max-width: 992px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 950px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }


}


@media(max-width: 767px) {

  .navbar {
    position: fixed;
    z-index: 1111;
    left: 0;
    top: 0px;
    height: auto;
    width: 100%;
  }

  .banner {
    position: relative;
    z-index: 111;
    align-items: flex-start;
    height: auto;
    padding-top: 100px;
    background-size: 70%;
    background-position: top right;
    padding-bottom: 50px;
  }

  .banner h1 {
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 38px;
    padding-top: 0px;
    margin-bottom: 0;
  }

  .banner .base-logo{
      height: 90px;
  }

  .primary-badge {
    font-size: 28px;
  }

  .navbar-collapse {
    position: fixed;
    left: -400px;
    opacity: 0;
    transition: all .3s linear;
    display: block !important;
  }

  .home-sm .navbar-collapse {
    position: fixed;
    top: 0;
    opacity: 1;
    width: 90%;
    left: 0;
    height: 100vh;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    z-index: 111;
    left: 0px;
    border-right: 1px solid #6C6C6C;
    transition: all .3s linear;
    display: block !important;
  }

  .cross-btn {
    font-size: 28px;
  }

  .navbar-toggler {
    color: #fff;
    font-size: 36px;
    border: none;
    background: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav {
    align-items: flex-start !important;
  }

  .navbar-brand img {
    height: 30px;
  }
  
  .launch{
    font-size: 22px;
  }

  /* .banner::after{
    content: "";
    background-color: rgba(0, 0, 0, 0.2);
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
  } */
  .sm-flex{
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
}



